<template>
    <div class="content">
        <div class="heading is-size-3">
            Upcoming Features
        </div>
        <ul>
            <li>Support multiple Crusade armies</li>
            <li>Ability to run a Crusade League from within the app</li>
            <li>Unit Database so you dont have to manually input power levels</li>
        </ul>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>